<template>
  <div>
    <div class="table_content">
      <commonTitle></commonTitle>
      <div class="search">
        <el-form ref="form" :model="form" label-width="80px" :inline="true">
          <el-form-item label="户室号:">
            <el-input v-model="form.houseNum" placeholder="请输入户室号" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="业主姓名:">
            <el-input v-model="form.ownerName" placeholder="请输入业主姓名" size="mini"></el-input>
          </el-form-item>
          <template>
            <button @click.prevent="common()" style="cursor: pointer;">搜索</button>
          </template>
        </el-form>
        
      </div>
      <!--表格-->
      <template>
        <el-card>
          <el-table
            ref="multipleTable"
            :data="houseChangeList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
          >
            <el-table-column property="villageName" label="小区名称" show-overflow-tooltip></el-table-column>

            <el-table-column property="towerNum" label="楼幢号" show-overflow-tooltip></el-table-column>
            <el-table-column property="unitName" label="单元名称" show-overflow-tooltip></el-table-column>
            <el-table-column property="houseNum" label="户室号" show-overflow-tooltip></el-table-column>
            <el-table-column property="ownerName" label="业主姓名" show-overflow-tooltip></el-table-column>
            <el-table-column property="idCard" label="身份证号码" show-overflow-tooltip></el-table-column>
            <el-table-column property="phone" label="联系电话" show-overflow-tooltip></el-table-column>
            <el-table-column property="beginTime" label="开始时间" show-overflow-tooltip></el-table-column>
            <el-table-column property="endTime" label="结束时间" show-overflow-tooltip></el-table-column>
          </el-table>
          <el-row>
            <el-pagination
              background
              style="margin-top: 50px"
              layout="prev, pager, next"
              :page-size="10"
              @current-change="handleCurrentChange"
              :total="totalNum"
            ></el-pagination>
          </el-row>
        </el-card>
      </template>
      <!--分页-->
    </div>
  </div>
</template>

<script>
import commonTitle from "../table/commonTitle";

export default {
  name: "houseChange",
  components: {
    commonTitle
  },
  data() {
    return {
      url: this.$Config.base_server,
      params: {
        access_token: localStorage.getItem("token"),
        limit: 10
      },
      houseChangeList: [], //过户记录数据集合
      totalNum: 10,
      loading: true,
      form: {
        houseNum: "", //户室号
        ownerName: "" //业主姓名
      }
    };
  },
  mounted() {
    this.common();
  },
  methods: {
    common(currentPage = 0) {
      const _this = this;
      _this.loading = true;
      _this.params.page = currentPage;
      _this.params.houseNum = _this.form.houseNum;
      _this.params.ownerName = _this.form.ownerName;
      //请求后台数据
      $.ajax({
        url: _this.url + "/api-public/getChangeOwnerHouse",
        data: _this.params,
        type: "get",
        success: function(result) {
          _this.houseChangeList = result.data;
          _this.totalNum = result.count;
          _this.loading = false;
        },
        error: function() {
          _this.loading = false;
        }
      });
    },
    handleCurrentChange(val) {
      //当前页数改变
      this.common(val);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style scoped>
</style>